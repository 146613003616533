uniform float u_fade_t;
uniform float u_opacity;
uniform sampler2D u_image0;
uniform sampler2D u_image1;
varying vec2 v_pos0;
varying vec2 v_pos1;

uniform float u_brightness_low;
uniform float u_brightness_high;

uniform float u_saturation_factor;
uniform float u_contrast_factor;
uniform vec3 u_spin_weights;

uniform float u_zoom;
uniform vec4 u_c0_color;
uniform vec4 u_c1_color;
uniform float u_c0_intensity;
uniform float u_c1_intensity;


vec4 colorMap(float k, vec4 baseColor) {
    vec4 outColor = vec4(0.0);
    outColor.rgb = baseColor.rgb * baseColor.a * k;
    outColor.a = baseColor.a * k;

    outColor = clamp(outColor, 0.0, 1.0);
    return outColor;
}

float adjustIntensity(float c, float zoom) {
    // adjust instensity based on zoom
    float min_z;
    float max_z;
    float min_mult;
    float max_mult;

    if (zoom <= 2.0) {
        min_z = 0.0;
        max_z = 2.0;

        min_mult = 0.03;
        max_mult = 0.11;
    } else {
        min_z = 0.0;
        max_z = 11.0;

        min_mult = 0.08;
        max_mult = 1.0;
    }

    float f = clamp((zoom - min_z) / (max_z - min_z), 0.0, 1.0);  // 0 to 1, linear
    f *= f;  // 0 to 1, quadratic

    return c * mix(min_mult, max_mult, f);
}


vec4 renderPixel(vec4 rawColor, float zoom) {
    float p0 = u_c0_intensity;
    float p1 = u_c1_intensity;

    float c0 = rawColor.r;
    float c1 = rawColor.a;

    c0 *= p0;
    c1 *= p1;

    float d = 0.25 / 255.0;

    if (c0 > d) {
        c0 = d + adjustIntensity(c0 - d, zoom);
    }

    if (c1 > d) {
        c1 = d + adjustIntensity(c1 - d, zoom);
    }

    c0 = clamp(c0, 0.0, 1.0) * 255.0;
    c1 = clamp(c1, 0.0, 1.0) * 255.0;

    float sp0 = ((p0 - 1.0) * 0.5) + 1.0;  // p scaled by 0.5, centered around 1.0
    float sp1 = ((p1 - 1.0) * 0.5) + 1.0;  // p scaled by 0.5, centered around 1.0

    vec4 color0 = colorMap(c0, u_c0_color * sp0);
    vec4 color1 = colorMap(c1, u_c1_color * sp1);

    vec4 outColor = vec4(0.0);

    outColor = color0 + color1;

    return outColor;
}


void main() {
    // read and cross-fade colors from the main and parent tiles
    vec4 color0 = texture2D(u_image0, v_pos0, 0.0);
    vec4 color1 = texture2D(u_image1, v_pos1, 0.0);
    
    color0 = renderPixel(color0, u_zoom);
    color1 = renderPixel(color1, u_zoom - 1.0);

    vec4 color = mix(color0, color1, u_fade_t);

#ifdef FOG
    // disable fog
    // out_color = fog_dither(fog_apply(out_color, v_fog_pos));
#endif

    gl_FragColor = vec4(color.rgb, color.a);

#ifdef OVERDRAW_INSPECTOR
    gl_FragColor = vec4(1.0);
#endif
}
