
import mapboxgl from "../../forks/mapbox-gl-js";  // during dev consider using soft link: cd dist && ln -s mapbox-gl-dev.js mapbox-gl.js
import "../../forks/mapbox-gl-js/dist/mapbox-gl.css";
import { DISPLAY_URL, BASEMAP_STYLE, TILE_URL, MIN_ZOOM, MAX_ZOOM, TILE_SIZE, COVERAGE_GEOJSON_URL, COLOR_0, COLOR_1, DEBUG } from "./config.js";
import customRasterFrag from '../shaders/custom_raster.fragment.glsl';
import customRasterVert from '../shaders/custom_raster.vertex.glsl';

const FIRST_LABELS_LAYER = "poi-label";
const AIRPORT_LABEL_LAYER = "airport-label";


mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;
// mapboxgl.workerCount = 8;
const map = new mapboxgl.Map({
    container: "map",
    projection: "globe",
    style: BASEMAP_STYLE,
    center: [0, 40],
    zoom: 2,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    hash: true,
    preserveDrawingBuffer: DEBUG,
});
window.map = map;


function initCollapsibles() {
    Array.from(document.getElementsByClassName("collapsible-toggle")).forEach(collapsibleToggle => {
        const collapsibleContent = collapsibleToggle.nextElementSibling;  // TODO: assert has class "collapsible-content"
        collapsibleToggle.addEventListener("click", () => {
            collapsibleToggle.classList.toggle("collapsed");
            collapsibleContent.classList.toggle("collapsed");
        });
    });
}

function initCloseButton() {
    const closeButton = document.getElementById("close-button");
    const optionsButton = document.getElementById("options-button");

    const controllerDiv = document.getElementById("controller");

    closeButton.addEventListener("click", () => {
        controllerDiv.style.display = "none";
    });

    optionsButton.addEventListener("click", () => {
        controllerDiv.style.display = "block";
    });
}


window.onload = () => {
    initCollapsibles();
    initCloseButton();
};


const trafficChannels = {
    // ground: {
    //     name: "Ground traffic",
    //     description: null,
    //     channel: 0,
    //     checkbox: document.getElementById("show-ground-traffic-toggle"),
    // },
    low: {
        name: "Low traffic",
        description: "Aircraft at or below 18,000 feet.",
        channel: 0,
        checkbox: document.getElementById("show-low-traffic-toggle"),
    },
    high: {
        name: "High traffic",
        description: "Aircraft above 18,000 feet.",
        channel: 1,
        checkbox: document.getElementById("show-high-traffic-toggle"),
    },
};



const channelsContainer = document.getElementById("traffic-channels-container");
const newDiv = document.createElement("datalist");
channelsContainer.appendChild(newDiv);
newDiv.outerHTML = `<datalist id="intensity-defaults">
    <option value="1">
</datalist>`;
    

Object.entries(trafficChannels).forEach(([key, { name, description, channel, checkboxId }]) => {
    const newDiv = document.createElement("label");
    channelsContainer.appendChild(newDiv);    
    newDiv.outerHTML = `<label for="${key}-intensity">
        <input id="${key}-intensity-range" name="${key}-intensity" type="range" min="0" max="1.2" step="0.01" value="1" list="intensity-defaults" disabled/>
        ${name}
        </label>
    <span class="description">${description}</span>`;
});

function initTogglingBaseMap() {
    const checkbox = document.getElementById("show-base-map-toggle");
    const onChange = () => {
        map.style.stylesheet.layers
            .filter((layer) => layer.type != "symbol" && layer.id != "background")
            .forEach((layer) =>
                map.setLayoutProperty(
                    layer.id,
                    "visibility",
                    checkbox.checked ? "visible" : "none"
                )
            );
    };
    onChange();  // trigger set default
    checkbox.addEventListener("change", onChange);
    checkbox.disabled = false;
}

function initTogglingPlaceLabels() {
    const checkbox = document.getElementById("show-place-labels-toggle");
    const onChange = () => {
        map.style.stylesheet.layers
            .filter((layer) => layer.type == "symbol" && layer.id != AIRPORT_LABEL_LAYER)
            .forEach((layer) =>
                map.setLayoutProperty(
                    layer.id,
                    "visibility",
                    checkbox.checked ? "visible" : "none"
                )
            );
    };
    onChange();  // trigger set default
    checkbox.addEventListener("change", onChange);
    checkbox.disabled = false;
}

function initTogglingAirportLabels() {
    const checkbox = document.getElementById("show-airport-labels-toggle");
    const onChange = () => {
        map.setLayoutProperty(
            AIRPORT_LABEL_LAYER,
            "visibility",
            checkbox.checked ? "visible" : "none"
        );
    };

    onChange();  // trigger set default
    checkbox.addEventListener("change", onChange);
    checkbox.disabled = false;
}

function initTogglingCoverage() {
    const checkbox = document.getElementById("show-coverage-toggle");
    const onChange = () => {
        map.setLayoutProperty(
            "coverage",
            "visibility",
            checkbox.checked ? "visible" : "none"
        );
    };

    onChange();  // trigger set default
    checkbox.addEventListener("change", onChange);
    checkbox.disabled = false;
}

function initRangingIntensity() {
    Object.entries(trafficChannels).forEach(([key, { name, description, channel, checkboxId }]) => {
        const intensityRange = document.getElementById(`${key}-intensity-range`);
        intensityRange.addEventListener("input", (event) => {
            mainLayer[`_target_c${channel}_intensity`] = intensityRange.value;
            requestAnimationFrame(step);
        });
        intensityRange.disabled = false;
    });
}


let mainLayer;


function step() {
    let triggerRepaint = false;

    if (mainLayer._target_c0_intensity != mainLayer.c0_intensity) {
        mainLayer.c0_intensity = mainLayer._target_c0_intensity;
        triggerRepaint = true;
    }

    if (mainLayer._target_c1_intensity != mainLayer.c1_intensity) {
        mainLayer.c1_intensity = mainLayer._target_c1_intensity;
        triggerRepaint = true;
    }

    if (triggerRepaint) {
        map.painter._terrain && map.painter._terrain.proxySourceCache.deallocRenderCache();
        map.triggerRepaint();
        requestAnimationFrame(step);
    }
}


map.addControl(new mapboxgl.NavigationControl());
// map.addControl(new mapboxgl.FullscreenControl());
map.addControl(new mapboxgl.ScaleControl({ unit: "metric" }));


map.on("load", () => {
    map.addSource("flight-path-tiles", {
        type: "raster-raw",
        tiles: [TILE_URL],
        tileSize: TILE_SIZE,
        minzoom: 0,
        maxzoom: 11,
        attribution:
            'Data from <a target="_top" rel="noopener" href="https://ADSBexchange.com">ADS-B Exchange</a>',
    });

    map.addLayer({
        id: "flight-paths",
        type: "custom-raster",
        source: "flight-path-tiles",
        minzoom: 0,
        maxzoom: 16,
        paint: {
            "raster-fade-duration": 200,
            "raster-resampling": "linear",
        },
    }, FIRST_LABELS_LAYER);

    map.addSource("coverage", {
        type: "geojson",
        data: COVERAGE_GEOJSON_URL,
    });

    map.addLayer({
        id: "coverage",
        type: "fill",
        source: "coverage",
        layout: {},
        paint: {
            "fill-color": "#0080ff",
            "fill-opacity": 0.5,
        },
    });

    initTogglingBaseMap();
    initTogglingPlaceLabels();
    initTogglingAirportLabels();
    initTogglingCoverage();

    mainLayer = map.getLayer("flight-paths");
    mainLayer.fragShader = customRasterFrag;
    mainLayer.vertShader = customRasterVert;
    mainLayer.c0_color = COLOR_0;
    mainLayer.c1_color = COLOR_1;
    mainLayer.c0_intensity = 1.0;
    mainLayer.c1_intensity = 1.0;

    initRangingIntensity();

    requestAnimationFrame(step);
});


window.downloadCanvasImage = function(filename, cameraPos, options={}) {
    if (!DEBUG) {
        throw "downloadCanvasImage only works in debug (local) mode because it requires preserveDrawingBuffer=true."
    }

    let { width, height, lowTrafficIntensity, highTrafficIntensity, labels, baseMap, overlay } = options;

    width = width ?? 1600;
    height = height ?? 1200;
    lowTrafficIntensity = lowTrafficIntensity ?? 1200;
    highTrafficIntensity = highTrafficIntensity ?? 1200;
    baseMap = baseMap ?? true;
    labels = labels ?? "country";
    overlay = overlay ?? "attribution";

    if (cameraPos) {
        const { x, y, z, bearing, pitch } = cameraPos;
        map.jumpTo({
            center: [x, y],
            zoom: z,
            bearing: bearing,
            pitch: pitch,
        });
    }

    map._container.style.width = `${width}px`;
    map._container.style.height = `${height}px`;

    const labelsCheckbox = document.getElementById("show-place-labels-toggle");
    const baseMapCheckbox = document.getElementById("show-base-map-toggle");
    
    let labelLayers = [];
    if (labels == "country") {
        labelLayers = ["water-point-label", "continent-label", "country-label"];
    } else if (labels == "state") {
        labelLayers = ["water-point-label", "continent-label", "country-label", "state-label"];
    } else if (labels == "major") {
        labelLayers = ["water-point-label", "continent-label", "country-label", "state-label", "settlement-major-label"];
    } else if (labels == "minor") {
        labelLayers = ["water-point-label", "continent-label", "country-label", "state-label", "settlement-major-label", "settlement-minor-label"];
    }
    // other layers = ["poi-label", "water-line-label", "waterway-label"]

    if (labels != "all") {
        if (labelsCheckbox.checked) labelsCheckbox.click();
        labelLayers.forEach(labelLayer => {
            map.setLayoutProperty(labelLayer, "visibility", "visible");
        });
    }
    
    if (baseMapCheckbox.checked != baseMap) {
        baseMapCheckbox.click()
    }

    mainLayer._target_c0_intensity = lowTrafficIntensity;
    mainLayer._target_c1_intensity = highTrafficIntensity;

    requestAnimationFrame(step);

    map.resize();
    map.triggerRepaint();

    setTimeout(() => {
        const canvas = map.getCanvas();

        const offscreenCanvas = new OffscreenCanvas(width, height);
        const ctx = offscreenCanvas.getContext("2d")
        ctx.drawImage(canvas, 0, 0);

        if (overlay == "attribution") {
            // Add overlay texts

            let attributionText = "Data from ADS-B Exchange";
            if (baseMap) {
                attributionText += " | © Mapbox © OpenStreetMap";
            }
            
            ctx.fillStyle = "#000a";
            ctx.rect(0, height - 24, width, 24);
            ctx.fill();

            ctx.font = "14px sans-serif";
            ctx.fillStyle = "#fffc";
            ctx.textAlign = "end";
            ctx.textBaseline = "bottom";
            ctx.fillText(attributionText, width - 8, height - 4);

            ctx.font = "bold 14px sans-serif";
            ctx.fillStyle = "#fffc";
            ctx.textAlign = "start";
            ctx.textBaseline = "bottom";
            ctx.fillText(DISPLAY_URL, 8, height - 4);

        } else if (overlay == "opengraph") {
            const rectHeight = 160;
            const text = "24 Hours of Global Air Traffic";

            ctx.fillStyle = "#000a";
            ctx.rect(0, height / 2 - rectHeight / 2, width, rectHeight);
            ctx.fill();

            ctx.font = "bold 48px sans-serif";
            ctx.fillStyle = "#fffc";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(text, width / 2, height / 2);
        }

        offscreenCanvas.convertToBlob({ type: "image/png" }).then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('download', filename ?? "canvas.png");
            link.setAttribute('href', blobUrl);
            link.click();
        });
    }, 2000);
}
