export const DISPLAY_URL = "24h-global-air-traffic.cbergillos.com"
export const BASEMAP_STYLE = "mapbox://styles/carlosair/cl9042nm4001n15mrmta394mm?optimize=true";
export const TILE_SIZE = 1024;
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 11;
export const COLOR_0 = [0.709, 0.168, 0.250, 0.45];  // #8c1d2e
export const COLOR_1 = [0.443, 0.239, 0.831, 0.45];  // #713dd4
export const PRODUCTION_ENVIRONMENT = process.env.NODE_ENV == "production";
export const DEBUG = !PRODUCTION_ENVIRONMENT;

let CDN_URL;
if (PRODUCTION_ENVIRONMENT) {
    CDN_URL = "https://24h-global-air-traffic.fra1.cdn.digitaloceanspaces.com";   
} else {
    CDN_URL = "http://192.168.1.44:8000";   
}

export const TILE_URL = `${CDN_URL}/tiles/20220901/${TILE_SIZE}/{z}/{x}/{y}.bin.gz`;
export const COVERAGE_GEOJSON_URL = `${CDN_URL}/coverage/20220928.geojson.gz`;
